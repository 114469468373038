import { useEffect, useState } from "react"
import { Client } from "../utils/prismicClient"
import Prismic from "prismic-javascript"

export default () => {
  const [gradeLevelFilters, setGradeLevelFilters] = useState([])
  const [interestsFilters, setInterestsFilters] = useState([])
  const [programLocationFilters, setProgramLocationFilters] = useState([])
  const [whenProgramOfferedFilters, setWhenProgramOfferedFilters] = useState([])
  const [whenPDOfferedFilters, setWhenPDOfferedFilters] = useState([])
  const [topicsFilters, setTopicsFilters] = useState([])
  const [incentivesFilters, setIncentivesFilters] = useState([])
  const [designedForFilters, setDesignedForFilters] = useState([])
  const [
    educationalStandardsOrGuidelinesFilters,
    setEducationalStandardsOrGuidelinesFilters,
  ] = useState([])
  useEffect(() => {
    async function fetchAllFilters() {
      const gradeLevelFilters = await Client.query(
        Prismic.Predicates.at("document.type", "grade_filter"),
        { orderings: "[my.grade_filter.order]", pageSize: 100 }
      )
      const interestsFilters = await Client.query(
        Prismic.Predicates.at("document.type", "interests_filter"),
        { orderings: "[my.interests_filter.interests_filter]", pageSize: 100 }
      )
      const programLocationFilters = await Client.query(
        Prismic.Predicates.at("document.type", "program_location_filter"),
        { orderings: "[my.program_location_filter.order]", pageSize: 100 }
      )
      const whenProgramOfferedFilters = await Client.query(
        Prismic.Predicates.at("document.type", "when_program_offered_filter"),
        { orderings: "[my.when_program_offered_filter.order]", pageSize: 100 }
      )
      const whenPDOfferedFilters = await Client.query(
        Prismic.Predicates.at("document.type", "when_pd_offered_filter"),
        { orderings: "[my.when_pd_offered_filter.order]", pageSize: 100 }
      )
      const topicsFilters = await Client.query(
        Prismic.Predicates.at("document.type", "topics_filter"),
        { orderings: "[my.topics_filter.topics_filter]", pageSize: 100 }
      )
      const incentivesFilters = await Client.query(
        Prismic.Predicates.at("document.type", "incentives_filter"),
        { orderings: "[my.incentives_filter.order]", pageSize: 100 }
      )
      const designedForFilters = await Client.query(
        Prismic.Predicates.at("document.type", "designed_for_filter"),
        {
          orderings: "[my.designed_for_filter.designed_for_filter]",
          pageSize: 100,
        }
      )
      const edStandardsFilters = await Client.query(
        Prismic.Predicates.at(
          "document.type",
          "educational_standards_or_guidelines_filter"
        ),
        {
          orderings: "[educational_standards_or_guidelines_filter.order]",
        }
      )

      const allFilters = [
        gradeLevelFilters.results,
        interestsFilters.results,
        programLocationFilters.results,
        whenProgramOfferedFilters.results,
        whenPDOfferedFilters.results,
        topicsFilters.results,
        incentivesFilters.results,
        designedForFilters.results,
        edStandardsFilters.results,
      ]

      for (let i = 0; i < allFilters.length; i++) {
        for (let k = 0; k < allFilters[i].length; k++) {
          if (allFilters[i][k].slugs[0] === "other") {
            const ref = allFilters[i][k]
            allFilters[i].splice(k, 1)
            allFilters[i].push(ref)
          }
        }
      }

      setGradeLevelFilters(gradeLevelFilters.results)
      setInterestsFilters(interestsFilters.results)
      setProgramLocationFilters(programLocationFilters.results)
      setWhenProgramOfferedFilters(whenProgramOfferedFilters.results)
      setWhenPDOfferedFilters(whenPDOfferedFilters.results)
      setTopicsFilters(topicsFilters.results)
      setIncentivesFilters(incentivesFilters.results)
      setDesignedForFilters(designedForFilters.results)
      setEducationalStandardsOrGuidelinesFilters(edStandardsFilters.results)
    }
    fetchAllFilters()
  }, [])
  return {
    gradeLevelFilters,
    interestsFilters,
    programLocationFilters,
    whenProgramOfferedFilters,
    whenPDOfferedFilters,
    topicsFilters,
    incentivesFilters,
    designedForFilters,
    educationalStandardsOrGuidelinesFilters,
  }
}

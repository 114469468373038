import { useEffect, useState } from "react"

const useProgramLocationFilters = programLocation => {
  const [programLocationFiltersState, setProgramLocationState] = useState({})
  const programLocationSelected = (name, event) => {
    setProgramLocationState({
      ...programLocationFiltersState,
      [event.target.name]: { checked: event.target.checked, name: name },
    })
  }
  const resetProgramLocationChecked = () => {
    const checked = {}
    programLocation.map(programLocation => {
      return (checked[programLocation.id] = {
        checked: false,
        name: programLocation.data[programLocation.type],
      })
    })
    setProgramLocationState(checked)
  }
  useEffect(() => {
    const checked = {}
    programLocation.map(programLocation => {
      return (checked[programLocation.id] = {
        checked: false,
        name: programLocation.data[programLocation.type],
      })
    })
    setProgramLocationState(checked)
  }, [programLocation])
  return {
    programLocationSelected,
    programLocationFiltersState,
    setProgramLocationState,
    resetProgramLocationChecked,
  }
}

export default useProgramLocationFilters

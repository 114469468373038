import React from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Link,
} from "@material-ui/core"

const CreditsDialog = ({ creditsDialog, handleCreditsDiagClose }) => {
  return (
    <Dialog
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      open={creditsDialog}
      onClose={handleCreditsDiagClose}
    >
      <DialogContent>
        <Box m={4}>
          <Link
            href="https://www.freepik.com/vectors/infographic"
            target="_blank"
            rel="noopener"
          >
            Brain Infographic vector created by freepik - www.freepik.com
          </Link>
        </Box>
        <Box m={4}>
          <Link
            href="https://theindustrialresolution.com/"
            target="_blank"
            rel="noopener"
          >
            Website Design and Implementation - Industrial Resolution
          </Link>
        </Box>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreditsDiagClose}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default CreditsDialog

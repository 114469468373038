import { useEffect, useState } from "react"

const useDesignedForFilters = designedForFilters => {
  const [designedForFiltersState, setDesignedForFiltersState] = useState({})
  const designedForSelected = (name, event) => {
    setDesignedForFiltersState({
      ...designedForFiltersState,
      [event.target.name]: { checked: event.target.checked, name: name },
    })
  }

  const resetDesignedForChecked = () => {
    const checked = {}
    designedForFilters.map(designedFor => {
      return (checked[designedFor.id] = {
        checked: false,
        name: designedFor.data[designedFor.type],
      })
    })
    setDesignedForFiltersState(checked)
  }

  useEffect(() => {
    const checked = {}
    designedForFilters.map(designedFor => {
      return (checked[designedFor.id] = {
        checked: false,
        name: designedFor.data[designedFor.type],
      })
    })
    setDesignedForFiltersState(checked)
  }, [designedForFilters])
  return {
    designedForSelected,
    designedForFiltersState,
    setDesignedForFiltersState,
    resetDesignedForChecked,
  }
}

export default useDesignedForFilters

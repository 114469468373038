import React from "react"
import styled from "styled-components"
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"

const StyledCostFilter = styled.div`
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const StyledFormControl = styled(FormControl)`
  width: 60%;
`

const CostFilter = ({ cost, onCostChange }) => {
  return (
    <StyledCostFilter>
      <StyledFormControl>
        <InputLabel>Participation fee</InputLabel>
        <Select value={cost} onChange={onCostChange}>
          <MenuItem value={"Yes"}>Yes</MenuItem>
          <MenuItem value={"No"}>No</MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
        </Select>
      </StyledFormControl>
    </StyledCostFilter>
  )
}

export default CostFilter

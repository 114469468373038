import { useEffect, useState } from "react"

const useWhenProgramOfferedFilters = whenProgramOffered => {
  const [
    whenProgramOfferedFiltersState,
    setWhenProgramOfferedFiltersState,
  ] = useState({})
  const whenProgramOfferedSelected = (name, event) => {
    setWhenProgramOfferedFiltersState({
      ...whenProgramOfferedFiltersState,
      [event.target.name]: { checked: event.target.checked, name: name },
    })
  }

  const resetWhenProgramOfferedChecked = () => {
    const checked = {}
    whenProgramOffered.map(whenProgramOffered => {
      return (checked[whenProgramOffered.id] = {
        checked: false,
        name: whenProgramOffered.data[whenProgramOffered.type],
      })
    })
    setWhenProgramOfferedFiltersState(checked)
  }

  useEffect(() => {
    const checked = {}
    whenProgramOffered.map(whenProgramOffered => {
      return (checked[whenProgramOffered.id] = {
        checked: false,
        name: whenProgramOffered.data[whenProgramOffered.type],
      })
    })
    setWhenProgramOfferedFiltersState(checked)
  }, [whenProgramOffered])
  return {
    whenProgramOfferedSelected,
    whenProgramOfferedFiltersState,
    setWhenProgramOfferedFiltersState,
    resetWhenProgramOfferedChecked,
  }
}

export default useWhenProgramOfferedFilters

import React from "react"
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Link,
} from "@material-ui/core"
import PhoneIcon from "@material-ui/icons/Phone"
import { LocationOn, Web } from "@material-ui/icons"

const MoreDetailsDialog = ({
  handleMoreDetailsDiagClose,
  moreDetailsDialog,
}) => {
  return (
    <Dialog open={moreDetailsDialog.open} onClose={handleMoreDetailsDiagClose}>
      {moreDetailsDialog.data && (
        <DialogContent>
          <Grid container spacing={3}>
            <Grid xs={12} item>
              <Typography variant="h5">
                {moreDetailsDialog.data.name}
              </Typography>
            </Grid>
            <Grid xs={1} item container alignItems="center">
              <LocationOn color="primary" />
            </Grid>
            <Grid xs={11} item>
              <Typography variant="body1">
                {moreDetailsDialog.data.street}
              </Typography>
              <Typography variant="body1">
                {moreDetailsDialog.data.city}, {moreDetailsDialog.data.state}{" "}
                {moreDetailsDialog.data.zip_code}
              </Typography>
            </Grid>
            <Grid xs={1} item container alignItems="center">
              <Web color="primary" />
            </Grid>
            <Grid xs={11} item container alignItems="center">
              <Link
                href={moreDetailsDialog.data.website.url}
                rel="noopener"
                target="_blank"
              >
                {moreDetailsDialog.data.website.url}
              </Link>
            </Grid>
            <Grid xs={1} item>
              <PhoneIcon color="primary" />
            </Grid>
            <Grid xs={11} item>
              <Typography variant="body1">
                {moreDetailsDialog.data.phone_number}
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Typography variant="body1">
                {moreDetailsDialog.data.description}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default MoreDetailsDialog

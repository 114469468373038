import { useEffect, useState } from "react"

const useInterestsFilters = interestsFilters => {
  const [interestsFiltersState, setInterestsFiltersState] = useState({})
  const interestsSelected = (name, event) => {
    setInterestsFiltersState({
      ...interestsFiltersState,
      [event.target.name]: { checked: event.target.checked, name: name },
    })
  }
  const resetInterestsChecked = () => {
    const checked = {}
    interestsFilters.map(interest => {
      return (checked[interest.id] = {
        checked: false,
        name: interest.data[interest.type],
      })
    })
    setInterestsFiltersState(checked)
  }
  useEffect(() => {
    const checked = {}
    interestsFilters.map(interest => {
      return (checked[interest.id] = {
        checked: false,
        name: interest.data[interest.type],
      })
    })
    setInterestsFiltersState(checked)
  }, [interestsFilters])
  return {
    interestsSelected,
    interestsFiltersState,
    setInterestsFiltersState,
    resetInterestsChecked,
  }
}

export default useInterestsFilters

import React from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Typography,
  Box,
  Link,
} from "@material-ui/core"
import styled from "styled-components"
// import brainLogo from "../images/PhilySTEM_Logo_Final.svg"
import brainLogo from "../images/phlSTEMecosystem.png"
import { ArrowForward } from "@material-ui/icons"

const Container = styled.div``

const WelcomeHeader = styled.div`
  text-align: left;
  font-size: 18px;
`
const HeaderTextLarge = styled.div`
  color: #45a6c9;
  font-size: 20px !important;
  padding-bottom: 15px;
`
const HeaderSubText = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  color: #515151;
  line-height: 140%;
  font-size: 16px;
`
const SelectionSection = styled.div`
  width: ${props => (props.desktopView ? "50vw" : "65w")};
  max-width: 100%;
`
const SelectionContainer = styled.div`
  // display: flex;
  // flex-direction: ${props => (props.desktopView ? "row" : "column")};
  padding: 10px 10px 10px 10px;
`
const Selection = styled.div`
  padding: 10px;
`
const SelectionHeader = styled.div`
  font-size: 18px;
  padding-bottom: 30px;
  padding-top: 20px;
  text-align: center;
`

const StyledPaperStu = styled(Paper)`
  padding: 10px;
  cursor: pointer;
  background: #45a6c9;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  &:hover {
    background: #45a6c9;
  }
`
const StyledPaperProf = styled(Paper)`
  padding: 10px;
  cursor: pointer;
  background: #6fc34b;
  color: #ffffff;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #6fc34b;
  }
`
const RoleSelect = props => (
  <Container>
    {/* <SelectionHeader>Who are you?</SelectionHeader> */}
    <SelectionContainer desktopView={props.desktopView}>
      <SelectionSection desktopView={props.desktopView}>
        <Selection>
          <StyledPaperStu
            onClick={e => props.roleSelected(e, "resource")}
            elevation={2}
          >
            <div>
              Are you a student or a parent/guardian looking for an
              <br />
              out-of-school time STEM program geared to students
              <br />
              from Pre-K through high school?
              <br />
              Enter here.
            </div>
          </StyledPaperStu>
        </Selection>
      </SelectionSection>
      <SelectionSection desktopView={props.desktopView}>
        <Selection>
          <StyledPaperProf
            onClick={e => props.roleSelected(e, "professional_resource")}
            elevation={2}
          >
            <div>
              Are you a teacher or educator searching for professional
              development opportunities in STEM education?
              <br />
              Enter here.
            </div>
          </StyledPaperProf>
        </Selection>
      </SelectionSection>
    </SelectionContainer>
  </Container>
)

const WelcomeDialog = ({
  welcomeDialog,
  handleWelcomeDiagClose,
  onRoleSelected,
  desktopView,
  selectedRole,
}) => {
  const [panelViewStepState, setPanelViewStepState] = React.useState({
    step: 1,
  })

  const nextStep = () => {
    setPanelViewStepState({ step: 2 })
  }

  return (
    <Dialog
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      open={welcomeDialog.open}
      onClose={handleWelcomeDiagClose}
    >
      <DialogContent>
        {/* {panelViewStepState.step === 2 && (
          <RoleSelect
            desktopView={desktopView}
            roleSelected={onRoleSelected}
          ></RoleSelect>
        )} */}
        {panelViewStepState.step === 1 && (
          <div>
            <WelcomeHeader>
              <Box pt={4} pb={4} display="flex" justifyContent="center">
                <img height="auto" width="80%" src={brainLogo}></img>
              </Box>
              <HeaderTextLarge>
                Welcome to the Philadelphia STEM Education Directory!
              </HeaderTextLarge>
              <HeaderSubText>
                The Directory is a free searchable map of a variety of
                opportunities in STEM in the Greater Philadelphia Area.
              </HeaderSubText>
              <RoleSelect
                desktopView={desktopView}
                roleSelected={onRoleSelected}
              ></RoleSelect>

              <Typography variant="subtitle2" color="textSecondary">
                Interested in being included in the directory? Please{" "}
                <Link
                  href="https://www.philastemeco.org/directory"
                  target="blank"
                >
                  register here
                </Link>
                . Inclusion in this directory is not an endorsement of the
                program. For additional details about any individual program or
                organization please contact the program directly.
              </Typography>
            </WelcomeHeader>

            {/* <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={nextStep}
                endIcon={<ArrowForward>send</ArrowForward>}
              >
                Continue
              </Button> */}
            {/* <Button onClick={handleWelcomeDiagClose}>Continue</Button> */}
            {/* </DialogActions> */}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default WelcomeDialog

import { useEffect, useState } from "react"

const useEdStandards = edStandardsFilters => {
  const [edStandardsFiltersState, setEdStandardsFiltersState] = useState({})
  const edStandardsSelected = (name, event) => {
    setEdStandardsFiltersState({
      ...edStandardsFiltersState,
      [event.target.name]: { checked: event.target.checked, name: name },
    })
  }

  const resetEdStandardsChecked = () => {
    const checked = {}
    edStandardsFilters.map(edStandard => {
      return (checked[edStandard.id] = {
        checked: false,
        name: edStandard.data[edStandard.type],
      })
    })

    setEdStandardsFiltersState(checked)
  }

  useEffect(() => {
    const checked = {}
    edStandardsFilters.map(edStandard => {
      return (checked[edStandard.id] = {
        checked: false,
        name: edStandard.data[edStandard.type],
      })
    })

    setEdStandardsFiltersState(checked)
  }, [edStandardsFilters])
  return {
    edStandardsSelected,
    edStandardsFiltersState,
    setEdStandardsFiltersState,
    resetEdStandardsChecked,
  }
}

export default useEdStandards

import { useEffect, useState } from "react"
const useWhenPDOfferedFilters = whenPDOffered => {
  const [whenPDOfferedFiltersState, setWhenPDOfferedFiltersState] = useState({})
  const whenPDOfferedSelected = (name, event) => {
    setWhenPDOfferedFiltersState({
      ...whenPDOfferedFiltersState,
      [event.target.name]: { checked: event.target.checked, name: name },
    })
  }

  const resetWhenPDOfferedChecked = () => {
    const checked = {}
    whenPDOffered.map(whenPDOffered => {
      return (checked[whenPDOffered.id] = {
        checked: false,
        name: whenPDOffered.data[whenPDOffered.type],
      })
    })
    setWhenPDOfferedFiltersState(checked)
  }

  useEffect(() => {
    const checked = {}
    whenPDOffered.map(whenPDOffered => {
      return (checked[whenPDOffered.id] = {
        checked: false,
        name: whenPDOffered.data[whenPDOffered.type],
      })
    })
    setWhenPDOfferedFiltersState(checked)
  }, [whenPDOffered])
  return {
    whenPDOfferedSelected,
    whenPDOfferedFiltersState,
    setWhenPDOfferedFiltersState,
    resetWhenPDOfferedChecked,
  }
}

export default useWhenPDOfferedFilters

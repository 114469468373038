import { useEffect, useState } from "react"

const useTopicsFilters = topicsFilters => {
  const [topicsFiltersState, setTopicsFiltersState] = useState({})
  const topicSelected = (name, event) => {
    setTopicsFiltersState({
      ...topicsFiltersState,
      [event.target.name]: { checked: event.target.checked, name: name },
    })
  }

  const resetTopicsChecked = () => {
    const checked = {}
    topicsFilters.map(topic => {
      return (checked[topic.id] = {
        checked: false,
        name: topic.data[topic.type],
      })
    })
    setTopicsFiltersState(checked)
  }

  useEffect(() => {
    const checked = {}
    topicsFilters.map(topic => {
      return (checked[topic.id] = {
        checked: false,
        name: topic.data[topic.type],
      })
    })
    setTopicsFiltersState(checked)
  }, [topicsFilters])
  return {
    topicSelected,
    topicsFiltersState,
    setTopicsFiltersState,
    resetTopicsChecked,
  }
}

export default useTopicsFilters

import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Typography, Drawer, Button, Fab, Box } from "@material-ui/core"

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: 100vh;
`
const ResourceContainer = styled.div`
  display: flex;
`
const useStyles = makeStyles(() => ({
  toolbar: {
    justifyContent: "space-between",
  },
  mainContainer: {
    paddingTop: "70px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const StyledHeaderText = styled(Typography)`
  color: white;
  font-weight: bold;
`
const StyledViewToggleButton = styled(Fab)`
  color: white;
  position: fixed;
  z-index: 2000;
  left: 0;
  bottom: 0;
  margin-bottom: 20px;
  margin-left: 20px;
`

const StyledDrawer = styled(Drawer)`
  z-index: 1000 !important;
`
const ResourceCardContainer = styled.div`
  overflow: auto;
`
const Resources = styled.div`
  padding-top: 65px;
`

const MainViewContainer = styled.div``

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6);
`
const ButtonDetailsStyled = styled(Button)`
  margin-left: -8px;
`
const StlyedCollapseControl = styled(Box)`
  justify-self: flex-end;
  background: #f0f0f0;
`
const StyledFilterButton = styled(Button)`
  background-color: #2796c0;
  &:hover {
    backgound-color: red !important;
  }
`
const StyledCreditsButton = styled(Button)`
  color: #0077a5;
  border: 1px solid #0077a5;
  background: #ffffff8c;
  z-index: 600;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 64px;
  margin-bottom: 25px;
`

export {
  PanelContainer,
  ResourceContainer,
  useStyles,
  StyledHeaderText,
  StyledFilterButton,
  StyledDrawer,
  Resources,
  ResourceCardContainer,
  MainViewContainer,
  StyledViewToggleButton,
  LoadingContainer,
  ButtonDetailsStyled,
  StlyedCollapseControl,
  StyledCreditsButton,
}

import React from "react"
import MainView from "../components/MainView"
import { ThemeProvider } from "@material-ui/core"
import { theme } from "../styles/theme"
import { setViewHeight } from "../utils/vhutil"
import ReactGA from "react-ga4"
ReactGA.initialize("G-KMJ6J8BTKF")

setViewHeight()

const Main = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <MainView></MainView>
      </ThemeProvider>
    </div>
  )
}

export default Main

import { useEffect, useState } from "react"

const useGradeLevelFilters = gradeLevelFilters => {
  const [gradeLevelFiltersState, setGradeLevelFiltersState] = useState({})
  const gradeSelected = (name, event) => {
    setGradeLevelFiltersState({
      ...gradeLevelFiltersState,
      [event.target.name]: { checked: event.target.checked, name: name },
    })
  }
  const resetGradeLevelsChecked = () => {
    const checked = {}
    gradeLevelFilters.map(grade => {
      return (checked[grade.id] = {
        checked: false,
        name: grade.data[grade.type][0].text,
      })
    })
    setGradeLevelFiltersState(checked)
  }

  useEffect(() => {
    const checked = {}
    gradeLevelFilters.map(grade => {
      return (checked[grade.id] = {
        checked: false,
        name: grade.data[grade.type][0].text,
      })
    })

    setGradeLevelFiltersState(checked)
  }, [gradeLevelFilters])
  return { gradeSelected, gradeLevelFiltersState, resetGradeLevelsChecked }
}

export default useGradeLevelFilters

import React from "react"
import styled from "styled-components"
import { MenuItem, Select, FormControl, InputLabel } from "@material-ui/core"

const RoleSelectorContainer = styled.div`
  display: flex;
  padding-top: 20px;
  height: 65px;
  flex-direction: column;
  margin: 20px;
  width: 65%;
`
const RoleSelector = ({ selectedRole, onRoleSelected }) => {
  return (
    <RoleSelectorContainer>
      <FormControl variant="outlined">
        <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedRole}
          onChange={onRoleSelected}
          label="Role"
        >
          <MenuItem value="resource">Student or Parent/Guardian</MenuItem>
          <MenuItem value="professional_resource">Teacher or Educator</MenuItem>
        </Select>
      </FormControl>
    </RoleSelectorContainer>
  )
}

export default RoleSelector

import React from "react"
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import GradeFilter from "../Filters/Shared/GradeFilter"
import CheckboxFilter from "../Filters/Shared/CheckboxFilter"
import CostFilter from "../Filters/Shared/CostFilter"
import TransportationFilter from "../Filters/StudentParent/TransportationFilter"

const ResourceFilters = ({
  gradeSelected,
  gradeLevelFiltersState,
  interestsSelected,
  interestsFiltersState,
  programLocationSelected,
  programLocationFiltersState,
  whenProgramOfferedSelected,
  whenProgramOfferedFiltersState,
  costSelected,
  costState,
  transportationSelected,
  transportationState,
}) => {
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Grade Level</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GradeFilter
            onGradeSelected={gradeSelected}
            gradeCheckedState={gradeLevelFiltersState}
          ></GradeFilter>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Interests</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CheckboxFilter
            checkboxSelected={interestsSelected}
            checkedState={interestsFiltersState}
          ></CheckboxFilter>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Program Location</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CheckboxFilter
            checkboxSelected={programLocationSelected}
            checkedState={programLocationFiltersState}
          ></CheckboxFilter>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>When Program Offered</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CheckboxFilter
            checkboxSelected={whenProgramOfferedSelected}
            checkedState={whenProgramOfferedFiltersState}
          ></CheckboxFilter>
        </AccordionDetails>
      </Accordion>
      <CostFilter cost={costState} onCostChange={costSelected}></CostFilter>
      <TransportationFilter
        transportation={transportationState}
        onTransportationChange={transportationSelected}
      ></TransportationFilter>
    </div>
  )
}

export default ResourceFilters

import React from "react"
import RoleSelector from "./FilterPanel/RoleSelector"
import {
  useMediaQuery,
  Grid,
  AppBar,
  Toolbar,
  Divider,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Button,
} from "@material-ui/core"
import Resource from "./Resource/Resource"
import { FilterList, List, Close } from "@material-ui/icons"
import MapIcon from "@material-ui/icons/Map"
import {
  StyledViewToggleButton,
  MainViewContainer,
  Resources,
  ResourceCardContainer,
  StyledDrawer,
  useStyles,
  ButtonDetailsStyled,
  StlyedCollapseControl,
  StyledFilterButton,
  StyledCreditsButton,
} from "./MainViewStyles"
import WelcomeDialog from "./WelcomeDialog"
import pefLogo from "../images/pef_horizontal_logo.jpg"
import mapIcon from "../images/map-marker.png"
import { TileLayer, Marker, Popup, ZoomControl, Map } from "react-leaflet"
import * as L from "leaflet"
import { useTheme } from "@material-ui/styles"
import MoreDetailsDialog from "./MoreDetailsDialog"
import useFetchAllFilters from "../Hooks/useFetchAllFilters"
import useInterestsFilters from "../Hooks/Filters/useInterestsFilters"
import useResourcesTypeSelect from "../Hooks/Filters/useResourceTypeSelect"
import useGradeLevelFilters from "../Hooks/Filters/useGradeLevelFilters"
import useCostFilter from "../Hooks/Filters/useCostFilter"
import useTopicsFilters from "../Hooks/Filters/useTopicsFilters"
import useIncentivesFilters from "../Hooks/Filters/useIncentivesFilters"
import useDesignedForFilters from "../Hooks/Filters/useDesignedFor"
import ProfessionalFilters from "./FilterPanel/ProfessionalFilters"
import ResourceFilters from "./FilterPanel/ResourceFilters"
import runFilterCheck from "./Services/RunFilterCheck"
import useHeightObserver from "../Hooks/Utils/useHeightObserver"
import useEdStandards from "../Hooks/Filters/useEducationalStandardsOrGuidelines"
import CreditsDialog from "./CreditsDialog"
import useProgramLocationFilters from "../Hooks/Filters/useProgramLocationFilters"
import useWhenProgramOfferedFilters from "../Hooks/Filters/useWhenProgramOfferedFilters"
import useWhenPDOfferedFilters from "../Hooks/Filters/useWhenPDOfferedFilters"

const MainView = () => {
  const theme = useTheme()
  const desktopView = useMediaQuery(theme.breakpoints.up("lg"))

  const [mapState] = React.useState({
    lat: 39.9536863,
    lng: -75.1688438,
    zoom: 13,
  })

  const position = [mapState.lat, mapState.lng]

  const { size } = useHeightObserver()

  const {
    gradeLevelFilters,
    interestsFilters,
    programLocationFilters,
    whenProgramOfferedFilters,
    whenPDOfferedFilters,
    topicsFilters,
    incentivesFilters,
    designedForFilters,
    educationalStandardsOrGuidelinesFilters,
  } = useFetchAllFilters()

  const {
    gradeSelected,
    gradeLevelFiltersState,
    resetGradeLevelsChecked,
  } = useGradeLevelFilters(gradeLevelFilters)

  const {
    interestsSelected,
    interestsFiltersState,
    resetInterestsChecked,
  } = useInterestsFilters(interestsFilters)

  const {
    topicSelected,
    topicsFiltersState,
    resetTopicsChecked,
  } = useTopicsFilters(topicsFilters)

  const {
    incentiveSelected,
    incentivesFiltersState,
    resetIncentivesChecked,
  } = useIncentivesFilters(incentivesFilters)

  const {
    designedForSelected,
    designedForFiltersState,
    resetDesignedForChecked,
  } = useDesignedForFilters(designedForFilters)

  const {
    edStandardsSelected,
    edStandardsFiltersState,
    resetEdStandardsChecked,
  } = useEdStandards(educationalStandardsOrGuidelinesFilters)

  const { costSelected, costState, setCostState } = useCostFilter()

  const {
    programLocationSelected,
    programLocationFiltersState,
    resetProgramLocationChecked,
  } = useProgramLocationFilters(programLocationFilters)

  const {
    whenProgramOfferedFiltersState,
    whenProgramOfferedSelected,
    resetWhenProgramOfferedChecked,
  } = useWhenProgramOfferedFilters(whenProgramOfferedFilters)

  const {
    whenPDOfferedFiltersState,
    whenPDOfferedSelected,
    resetWhenPDOfferedChecked,
  } = useWhenPDOfferedFilters(whenPDOfferedFilters)

  const { selectedRole, setSelectedRole, resources } = useResourcesTypeSelect()

  const [filteredResources, setFilteredResources] = React.useState(null)

  const [transportationState, setTransportationState] = React.useState("Other")

  // Left Filter Panel open-closed state
  const [open, setFilterPanelState] = React.useState({
    open: true, // default filters open when page loads so users can see what's available
  })

  const [currentResourceView, setResourceView] = React.useState({
    view: "map",
  })

  // Welcome Dialog open-closed state
  const [welcomeDialog, setWelcomeDialogState] = React.useState({
    open: true,
  })

  // Credits Dialog open-closed state
  const [creditsDialog, setCreditsDialogState] = React.useState(false)
  const [moreDetailsDiag, setMoreDetailsDiagState] = React.useState({
    open: false,
    data: null,
  })

  const transportationSelected = event => {
    setTransportationState(event.target.value)
  }

  const onRoleSelectedPanel = e => {
    setSelectedRole(e.target.value)
    // Reset all resource filters
    resetAllFilters()
  }

  const resetAllFilters = () => {
    setCostState("Other")
    setTransportationState("Other")
    resetGradeLevelsChecked()
    resetDesignedForChecked()
    resetEdStandardsChecked()
    resetIncentivesChecked()
    resetInterestsChecked()
    resetTopicsChecked()
    resetProgramLocationChecked()
    resetWhenProgramOfferedChecked()
    resetWhenPDOfferedChecked()
  }

  const toggleDrawer = () => {
    if (open.open) {
      setFilterPanelState({ open: false })
    } else {
      setFilterPanelState({ open: true })
    }
  }

  const toggleView = () => {
    if (currentResourceView.view === "map") {
      setResourceView({ view: "list" })
    } else {
      setResourceView({ view: "map" })
    }
  }

  const handleWelcomeDiagClose = () => {
    setWelcomeDialogState({ open: false })
  }

  const handleCreditsDiagClose = () => {
    setCreditsDialogState(false)
  }

  const handleMoreDetailsDiagClose = () => {
    setMoreDetailsDiagState({
      open: false,
      data: null,
    })
  }

  const onRoleSelected = (e, resource) => {
    setSelectedRole(resource)
    setWelcomeDialogState({ open: false })
  }

  const handleMoreDetailsDiagClick = resource => {
    setMoreDetailsDiagState({
      open: true,
      data: resource,
    })
  }

  const handleCreditsDiagClick = () => {
    setCreditsDialogState(true)
  }

  const mapHeight = { height: size - 59 }
  const resourceCardHeight = { height: size }

  // Resource API call - Move to new seperate service
  React.useEffect(() => {
    if (resources) {
      const {
        runGradeFilter,
        runInterestsFilter,
        runProgramLocationFilter,
        runWhenProgramOfferedFilter,
        runWhenPDOfferedFilter,
        runDesignedForFilter,
        runIncentivesFilter,
        runTopicsFilter,
        runEdStandardsFilter,
      } = runFilterCheck(
        gradeLevelFiltersState,
        interestsFiltersState,
        programLocationFiltersState,
        whenProgramOfferedFiltersState,
        whenPDOfferedFiltersState,
        designedForFiltersState,
        incentivesFiltersState,
        topicsFiltersState,
        edStandardsFiltersState
      )

      const filtered = resources
        .filter(resource => {
          if (runGradeFilter) {
            for (const key in resource.data.grade_level) {
              const gradeLevelId =
                resource.data.grade_level[key].grade_level_filter.id
              if (
                resource.data.grade_level[key].grade_level_filter.id in
                  gradeLevelFiltersState &&
                gradeLevelFiltersState[gradeLevelId].checked === true
              ) {
                return true
              }
            }
          } else {
            return true
          }
        })
        .filter(resource => {
          // Cost Filter
          if (!welcomeDialog.open && costState !== "Other") {
            return resource.data.cost === costState
          } else {
            return true
          }
        })
        .filter(resource => {
          // Interests Filter
          if (runInterestsFilter) {
            for (const key in resource.data.interests) {
              const interestsId =
                resource.data.interests[key].interests_filter.id
              if (
                resource.data.interests[key].interests_filter.id in
                  interestsFiltersState &&
                interestsFiltersState[interestsId].checked === true
              ) {
                return true
              }
            }
          } else {
            return true
          }
        })
        .filter(resource => {
          // Transportation Filter
          if (!welcomeDialog.open && transportationState !== "Other") {
            return resource.data.transportation === transportationState
          } else {
            return true
          }
        })
        .filter(resource => {
          if (runProgramLocationFilter) {
            for (const key in resource.data.program_location) {
              const programLocationId =
                resource.data.program_location[key].program_location_filter.id
              if (
                resource.data.program_location[key].program_location_filter
                  .id in programLocationFiltersState &&
                programLocationFiltersState[programLocationId].checked === true
              ) {
                return true
              }
            }
          } else {
            return true
          }
        })
        .filter(resource => {
          if (runWhenProgramOfferedFilter) {
            for (const key in resource.data.when_program_offered) {
              const whenProgramOfferedId =
                resource.data.when_program_offered[key]
                  .when_program_offered_filter.id
              if (
                resource.data.when_program_offered[key]
                  .when_program_offered_filter.id in
                  whenProgramOfferedFiltersState &&
                whenProgramOfferedFiltersState[whenProgramOfferedId].checked ===
                  true
              ) {
                return true
              }
            }
          } else {
            return true
          }
        })
        .filter(resource => {
          if (runWhenPDOfferedFilter) {
            for (const key in resource.data.when_pd_offered) {
              const whenPDOfferedId =
                resource.data.when_pd_offered[key].when_pd_offered_filter.id
              if (
                resource.data.when_pd_offered[key].when_pd_offered_filter.id in
                  whenPDOfferedFiltersState &&
                whenPDOfferedFiltersState[whenPDOfferedId].checked === true
              ) {
                return true
              }
            }
          } else {
            return true
          }
        })
        .filter(resource => {
          if (runDesignedForFilter) {
            for (const key in resource.data.designed_for) {
              const designedForId =
                resource.data.designed_for[key].designed_for_filter.id
              if (
                resource.data.designed_for[key].designed_for_filter.id in
                  designedForFiltersState &&
                designedForFiltersState[designedForId].checked === true
              ) {
                return true
              }
            }
          } else {
            return true
          }
        })
        .filter(resource => {
          if (runTopicsFilter) {
            for (const key in resource.data.topics) {
              const topicId = resource.data.topics[key].topics_filter.id
              if (
                resource.data.topics[key].topics_filter.id in
                  topicsFiltersState &&
                topicsFiltersState[topicId].checked === true
              ) {
                return true
              }
            }
          } else {
            return true
          }
        })
        .filter(resource => {
          if (runIncentivesFilter) {
            for (const key in resource.data.incentives) {
              const incentiveId =
                resource.data.incentives[key].incentives_filter.id
              if (
                resource.data.incentives[key].incentives_filter.id in
                  incentivesFiltersState &&
                incentivesFiltersState[incentiveId].checked === true
              ) {
                return true
              }
            }
          } else {
            return true
          }
        })
        .filter(resource => {
          if (runEdStandardsFilter) {
            for (const key in resource.data
              .educational_standards_or_guidelines) {
              const edStandardsId =
                resource.data.educational_standards_or_guidelines[key]
                  .educational_standards_or_guidelines_filter.id
              if (
                resource.data.educational_standards_or_guidelines[key]
                  .educational_standards_or_guidelines_filter.id in
                  edStandardsFiltersState &&
                edStandardsFiltersState[edStandardsId].checked === true
              ) {
                return true
              }
            }
          } else {
            return true
          }
        })
      setFilteredResources(filtered)
    }
  }, [
    resources,
    gradeLevelFiltersState,
    costState,
    transportationState,
    interestsFiltersState,
    programLocationFiltersState,
    whenProgramOfferedFiltersState,
    whenPDOfferedFiltersState,
    topicsFiltersState,
    designedForFiltersState,
    incentivesFiltersState,
    edStandardsFiltersState,
    welcomeDialog.open,
  ])

  const classes = useStyles()

  let customMapIcon

  if (typeof window !== "undefined") {
    customMapIcon = new L.Icon({
      iconUrl: mapIcon,
      iconSize: [mapState.zoom * 3, mapState.zoom * 3],
    })
  } else {
    return null
  }

  const MapView = () => (
    <Map style={mapHeight} center={position} zoom={mapState.zoom} on>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
      />
      <ZoomControl position="bottomright"></ZoomControl>
      {filteredResources &&
        // !welcomeDialog.open &&
        filteredResources.map((result, key) => (
          <div key={key}>
            {result.data.location !== undefined &&
              result.data.location.latitude &&
              result.data.location.longitude && (
                <Marker
                  position={[
                    result.data.location.latitude,
                    result.data.location.longitude,
                  ]}
                  icon={customMapIcon ? customMapIcon : null}
                  key={key}
                  onMouseOver={e => {
                    e.target.openPopup()
                  }}
                >
                  <Popup>
                    <Grid container spacing={1}>
                      <Grid xs={12} item>
                        <Typography variant="subtitle1">
                          {result.data.name}
                        </Typography>
                      </Grid>
                      <Grid xs={12} item>
                        <Typography variant="subtitle2">
                          {result.data.street
                            ? result.data.street
                            : "Undefined"}
                        </Typography>
                        <Typography variant="subtitle2">
                          {result.data.city}, {result.data.state},{" "}
                          {result.data.zip_code}
                        </Typography>
                      </Grid>
                      <Grid xs={12} item>
                        {result.data.description !== null && (
                          <Typography variant="subtitle2">
                            {result.data.description.substring(0, 100)}...
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <ButtonDetailsStyled
                      color="primary"
                      variant="text"
                      onClick={() => handleMoreDetailsDiagClick(result.data)}
                    >
                      More details
                    </ButtonDetailsStyled>
                  </Popup>
                </Marker>
              )}
          </div>
        ))}
    </Map>
  )

  return (
    <div>
      {!welcomeDialog.open && (
        <StyledCreditsButton
          color="secondary"
          variant="outlined"
          onClick={() => handleCreditsDiagClick()}
        >
          Credits
        </StyledCreditsButton>
      )}
      {!welcomeDialog.open && !open.open && !desktopView && (
        <StyledViewToggleButton
          onClick={toggleView}
          color="primary"
          variant="extended"
        >
          {currentResourceView.view === "list" && <MapIcon />}
          {currentResourceView.view === "map" && <List />}
          VIEW {currentResourceView.view === "map" ? "list" : "map"}
        </StyledViewToggleButton>
      )}
      <MoreDetailsDialog
        handleMoreDetailsDiagClose={handleMoreDetailsDiagClose}
        moreDetailsDialog={moreDetailsDiag}
      />
      <WelcomeDialog
        handleWelcomeDiagClose={handleWelcomeDiagClose}
        welcomeDialog={welcomeDialog}
        onRoleSelected={onRoleSelected}
        desktopView={desktopView}
        selectedRole={selectedRole}
      />
      <CreditsDialog
        handleCreditsDiagClose={handleCreditsDiagClose}
        creditsDialog={creditsDialog}
      ></CreditsDialog>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Box display="flex" alignItems="center" m={1}>
            <img height="70px" src={pefLogo}></img>
          </Box>
          <Box display="flex">
            <Box mr={desktopView ? 3 : 0}>
              <Button
                color="secondary"
                style={{ color: "#ffffff" }}
                href="https://www.philastemeco.org/"
              >
                STEM Ecosystem
              </Button>
            </Box>
            <Box pl={2} display="flex" alignItems="center">
              <Tooltip title="Show Filters">
                <StyledFilterButton
                  style={{ color: "#ffffff" }}
                  aria-label="open filters"
                  onClick={toggleDrawer}
                  variant="contained"
                  color="primary"
                  startIcon={<FilterList />}
                >
                  Filters
                </StyledFilterButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <StyledDrawer
        open={open.open}
        onClose={toggleDrawer}
        variant="temporary"
        anchor="right"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <StlyedCollapseControl
          mt={11}
          display="flex"
          justifyContent="space-between"
        >
          <Box display="flex" justifyContent="flex-start" py={2} pl={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={resetAllFilters}
            >
              Reset Filters
            </Button>
          </Box>
          <Tooltip title="Hide">
            <IconButton
              color="primary"
              aria-label="hide"
              className="hideFilterDrawer"
              onClick={toggleDrawer}
            >
              <Close fontSize="large" />
            </IconButton>
          </Tooltip>
        </StlyedCollapseControl>
        <Box>
          <RoleSelector
            selectedRole={selectedRole}
            onRoleSelected={onRoleSelectedPanel}
          ></RoleSelector>
          <Divider />
          <div>
            {selectedRole === "resource" && (
              <ResourceFilters
                gradeSelected={gradeSelected}
                gradeLevelFiltersState={gradeLevelFiltersState}
                interestsSelected={interestsSelected}
                interestsFiltersState={interestsFiltersState}
                programLocationSelected={programLocationSelected}
                programLocationFiltersState={programLocationFiltersState}
                whenProgramOfferedSelected={whenProgramOfferedSelected}
                whenProgramOfferedFiltersState={whenProgramOfferedFiltersState}
                costSelected={costSelected}
                costState={costState}
                transportationSelected={transportationSelected}
                transportationState={transportationState}
              ></ResourceFilters>
            )}
            {selectedRole === "professional_resource" && (
              <ProfessionalFilters
                gradeSelected={gradeSelected}
                gradeLevelFiltersState={gradeLevelFiltersState}
                designedForSelected={designedForSelected}
                designedForFiltersState={designedForFiltersState}
                topicSelected={topicSelected}
                topicsFiltersState={topicsFiltersState}
                incentiveSelected={incentiveSelected}
                incentivesFiltersState={incentivesFiltersState}
                whenPDOfferedSelected={whenPDOfferedSelected}
                whenPDOfferedFiltersState={whenPDOfferedFiltersState}
                educationalStandardsOrGuidelinesSelected={edStandardsSelected}
                educationalStandardsOrGuidelinesFiltersState={
                  edStandardsFiltersState
                }
              ></ProfessionalFilters>
            )}
          </div>
        </Box>
      </StyledDrawer>

      <MainViewContainer style={mapHeight}>
        <Grid container>
          {desktopView && (
            <Grid item xs={3}>
              <ResourceCardContainer style={resourceCardHeight}>
                <Resources>
                  {filteredResources &&
                    !welcomeDialog.open &&
                    filteredResources.map((result, key) => (
                      <Grid item xs={12} key={key}>
                        <Resource key={key} data={result.data}></Resource>
                      </Grid>
                    ))}
                </Resources>
              </ResourceCardContainer>
            </Grid>
          )}
          {desktopView && (
            <Grid item xs={9}>
              {typeof window !== "undefined" ? <MapView></MapView> : null}
            </Grid>
          )}
          {!desktopView && currentResourceView.view === "map" && (
            <Grid item xs={12}>
              {typeof window !== "undefined" ? <MapView></MapView> : null}
            </Grid>
          )}
          {!desktopView && currentResourceView.view === "list" && (
            <Grid item xs={12}>
              <ResourceCardContainer style={resourceCardHeight}>
                <Resources>
                  {filteredResources &&
                    !welcomeDialog.open &&
                    filteredResources.map((result, key) => (
                      <Grid item xs={12} key={key}>
                        <Resource key={key} data={result.data}></Resource>
                      </Grid>
                    ))}
                </Resources>
              </ResourceCardContainer>
            </Grid>
          )}
        </Grid>
      </MainViewContainer>
    </div>
  )
}

export default MainView

import { useState } from "react"

const useCostFilter = () => {
  const costSelected = event => {
    setCostState(event.target.value)
  }

  const [costState, setCostState] = useState("Other")

  return { costSelected, costState, setCostState }
}

export default useCostFilter

import { useState, useLayoutEffect } from "react"

const useHeightObserver = () => {
  const [size, setSize] = useState(0)
  useLayoutEffect(() => {
    function updateSize() {
      if (typeof window !== "undefined") {
        setSize(window.innerHeight)
      } else {
        return null
      }
    }
    if (typeof window !== "undefined") {
      window.addEventListener("resize", updateSize)
    } else {
      return null
    }
    updateSize()
    return () =>
      typeof window !== "undefined"
        ? window.removeEventListener("resize", updateSize)
        : null
  }, [])
  return { size }
}

export default useHeightObserver

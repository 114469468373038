import React from "react"
import styled from "styled-components"
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"

const StyledTransportationFilter = styled.div`
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const StyledFormControl = styled(FormControl)`
  width: 60%;
`

const TransportationFilter = ({ onTransportationChange, transportation }) => {
  return (
    <StyledTransportationFilter>
      <StyledFormControl>
        <InputLabel>Transportation assistance</InputLabel>
        <Select value={transportation} onChange={onTransportationChange}>
          <MenuItem value={"Yes"}>Yes</MenuItem>
          <MenuItem value={"No"}>No</MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
        </Select>
      </StyledFormControl>
    </StyledTransportationFilter>
  )
}

export default TransportationFilter

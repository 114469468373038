import React from "react"
import styled from "styled-components"
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"

const StyledCheckboxFilter = styled.div`
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const CheckboxFilter = ({ checkboxSelected, checkedState }) => {
  return (
    <StyledCheckboxFilter>
      <FormControl>
        <FormGroup>
          {checkedState &&
            Object.keys(checkedState).map(key => (
              <FormControlLabel
                onChange={e => checkboxSelected(checkedState[key].name, e)}
                key={key}
                control={<Checkbox />}
                label={checkedState[key].name}
                name={key}
                checked={checkedState[key].checked}
              />
            ))}
        </FormGroup>
      </FormControl>
    </StyledCheckboxFilter>
  )
}

export default CheckboxFilter

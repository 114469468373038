import { useEffect, useState } from "react"

const useIncentivesFilters = incentivesFilters => {
  const [incentivesFiltersState, setIncentivesFiltersState] = useState({})
  const incentiveSelected = (name, event) => {
    setIncentivesFiltersState({
      ...incentivesFiltersState,
      [event.target.name]: { checked: event.target.checked, name: name },
    })
  }

  const resetIncentivesChecked = () => {
    const checked = {}
    incentivesFilters.map(incentive => {
      return (checked[incentive.id] = {
        checked: false,
        name: incentive.data[incentive.type],
      })
    })
    setIncentivesFiltersState(checked)
  }

  useEffect(() => {
    const checked = {}
    incentivesFilters.map(incentive => {
      return (checked[incentive.id] = {
        checked: false,
        name: incentive.data[incentive.type],
      })
    })
    setIncentivesFiltersState(checked)
  }, [incentivesFilters])
  return {
    incentiveSelected,
    incentivesFiltersState,
    setIncentivesFiltersState,
    resetIncentivesChecked,
  }
}

export default useIncentivesFilters

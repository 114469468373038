import { useEffect, useState } from "react"
import Prismic from "prismic-javascript"
import { Client } from "../../utils/prismicClient"

const useResourceTypeSelect = () => {
  const [selectedRole, setSelectedRole] = useState("")
  const [resources, setResources] = useState(null)
  const [totalPages, setTotalPages] = useState(null)

  useEffect(() => {
    const Loadedresources = []
    const fetchPagesCount = async () => {
      const response = await Client.query(
        Prismic.Predicates.at("document.type", selectedRole),
        { pageSize: 100 }
      )
      if (response) {
        setTotalPages(response.total_pages)
      }
    }
    fetchPagesCount()
    if (totalPages) {
      for (let i = 0; i < totalPages; i++) {
        const pageNumber = i + 1
        const fetchResources = async () => {
          const resourceOrderQuery = "[my." + selectedRole + ".name]"
          const response = await Client.query(
            Prismic.Predicates.at("document.type", selectedRole),
            { orderings: resourceOrderQuery, pageSize: 100, page: pageNumber }
          )
          if (response) {
            const newResources = Loadedresources.concat(response.results)
            if (pageNumber === totalPages) {
              setResources(newResources)
            }
          }
        }
        if (selectedRole) {
          fetchResources()
        }
      }
    }
  }, [selectedRole, totalPages])
  return { selectedRole, setSelectedRole, resources }
}

export default useResourceTypeSelect

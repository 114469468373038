import React from "react"
import {
  CardContent,
  Typography,
  Card,
  Grid,
  Link,
  Box,
} from "@material-ui/core"

import styled from "styled-components"
import PhoneIcon from "@material-ui/icons/Phone"
import { LocationOn, Web } from "@material-ui/icons"
import ShowMore from "react-show-more"

const StyledCard = styled(Card)`
  margin: 30px;
`

const Resource = ({ data }) => {
  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={3}>
          <Grid xs={12} item container alignItems="center">
            <Typography variant="h5">{data.name}</Typography>
          </Grid>
          {(data.street || data.city || data.state || data.zip_code) && (
            <Grid xs={12} item>
              <Box display="flex">
                <Box pr={2}>
                  <LocationOn color="primary" />
                </Box>
                <Box>
                  <Typography variant="body1">{data.street}</Typography>
                  <Typography variant="body1">
                    {data.city}, {data.state} {data.zip_code}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
          {data.website.url && (
            <Grid xs={12} item>
              <Box display="flex">
                <Box pr={2}>
                  <Web color="primary" />
                </Box>
                <Box display="flex">
                  <Link
                    className="website-url"
                    href={data.website.url}
                    rel="noopener"
                    target="_blank"
                  >
                    {data.website.url}
                  </Link>
                </Box>
              </Box>
            </Grid>
          )}
          {data.phone_number && (
            <Grid xs={12} item>
              <Box display="flex">
                <Box pr={2}>
                  <PhoneIcon color="primary" />
                </Box>
                <Box>
                  <Typography variant="body1">{data.phone_number}</Typography>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid xs={12} item>
            <ShowMore
              lines={3}
              more="Show more"
              less="Show less"
              anchorClass="show-more-anchor"
            >
              {data.description}
            </ShowMore>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  )
}

export default Resource

const runFilterCheck = (
  gradeLevelFiltersState,
  interestsFiltersState,
  programLocationFiltersState,
  whenProgramOfferedFiltersState,
  whenPDOfferedFiltersState,
  designedForFiltersState,
  incentivesFiltersState,
  topicsFiltersState,
  edStandardsFiltersState
) => {
  let runGradeFilter = false
  for (const key in gradeLevelFiltersState) {
    if (gradeLevelFiltersState[key].checked === true) {
      runGradeFilter = true
      break
    }
  }

  let runInterestsFilter = false
  for (const key in interestsFiltersState) {
    if (interestsFiltersState[key].checked === true) {
      runInterestsFilter = true
      break
    }
  }

  let runProgramLocationFilter = false
  for (const key in programLocationFiltersState) {
    if (programLocationFiltersState[key].checked === true) {
      runProgramLocationFilter = true
      break
    }
  }

  let runWhenProgramOfferedFilter = false
  for (const key in whenProgramOfferedFiltersState) {
    if (whenProgramOfferedFiltersState[key].checked === true) {
      runWhenProgramOfferedFilter = true
      break
    }
  }

  let runWhenPDOfferedFilter = false
  for (const key in whenPDOfferedFiltersState) {
    if (whenPDOfferedFiltersState[key].checked === true) {
      runWhenPDOfferedFilter = true
      break
    }
  }

  let runDesignedForFilter = false
  for (const key in designedForFiltersState) {
    if (designedForFiltersState[key].checked === true) {
      runDesignedForFilter = true
      break
    }
  }

  let runIncentivesFilter = false
  for (const key in incentivesFiltersState) {
    if (incentivesFiltersState[key].checked === true) {
      runIncentivesFilter = true
      break
    }
  }

  let runTopicsFilter = false
  for (const key in topicsFiltersState) {
    if (topicsFiltersState[key].checked === true) {
      runTopicsFilter = true
      break
    }
  }

  let runEdStandardsFilter = false
  for (const key in edStandardsFiltersState) {
    if (edStandardsFiltersState[key].checked === true) {
      runEdStandardsFilter = true
      break
    }
  }

  return {
    runGradeFilter,
    runInterestsFilter,
    runProgramLocationFilter,
    runWhenProgramOfferedFilter,
    runWhenPDOfferedFilter,
    runDesignedForFilter,
    runIncentivesFilter,
    runTopicsFilter,
    runEdStandardsFilter,
  }
}

export default runFilterCheck

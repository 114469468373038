import React from "react"
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import GradeFilter from "../Filters/Shared/GradeFilter"
import CheckboxFilter from "../Filters/Shared/CheckboxFilter"

const ProfessionalFilters = ({
  gradeSelected,
  gradeLevelFiltersState,
  designedForSelected,
  designedForFiltersState,
  topicSelected,
  topicsFiltersState,
  incentiveSelected,
  incentivesFiltersState,
  whenPDOfferedSelected,
  whenPDOfferedFiltersState,
  educationalStandardsOrGuidelinesSelected,
  educationalStandardsOrGuidelinesFiltersState,
}) => {
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Grade Levels</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GradeFilter
            onGradeSelected={gradeSelected}
            gradeCheckedState={gradeLevelFiltersState}
          ></GradeFilter>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Designed For</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CheckboxFilter
            checkboxSelected={designedForSelected}
            checkedState={designedForFiltersState}
          ></CheckboxFilter>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Topics</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CheckboxFilter
            checkboxSelected={topicSelected}
            checkedState={topicsFiltersState}
          ></CheckboxFilter>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Incentives</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CheckboxFilter
            checkboxSelected={incentiveSelected}
            checkedState={incentivesFiltersState}
          ></CheckboxFilter>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>When PD Offered</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CheckboxFilter
            checkboxSelected={whenPDOfferedSelected}
            checkedState={whenPDOfferedFiltersState}
          ></CheckboxFilter>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Educational Standards or Guidelines</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CheckboxFilter
            checkboxSelected={educationalStandardsOrGuidelinesSelected}
            checkedState={educationalStandardsOrGuidelinesFiltersState}
          ></CheckboxFilter>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default ProfessionalFilters
